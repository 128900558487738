import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationTr from "./locales/tr.json";
import translationENG from "./locales/en.json";

const resources = {
    tr: {
        translation: translationTr,
    },

    en: {
        translation: translationENG,
    }
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
    localStorage.setItem("I18N_LANGUAGE", "tr");
}

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "tr",
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;