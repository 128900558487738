import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getUserDataFromAuth, getFirmsByUserId, getFirmById, editFirm } from "../../slices/thunks";
import { sendNotify, useHandleError } from "../../helpers/utils";
import { Card, CardBody, Col, Container, Input, Label, Row, Form, FormGroup, Button, Spinner } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditFirm = () => {
    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    const navigate = useNavigate();
    const [isFirmAdmin, setFirmAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [firmDetails, setFirmDetails] = useState({
        name: '',
        active: false,
        email: '',
        phoneNumber: '',
        vknNumber: '',
        parentFirmId: ''
    });
    document.title = t("AdminSettings_EditFirm") + " | Tanasoft";

    useEffect(() => {
        dispatch(getUserDataFromAuth()).then((authResponse) => {
            const userId = authResponse.id;
            dispatch(getFirmsByUserId(userId)).then(response => {
                const firm = response.find(firm => firm.firmId === companyData.companyID);
                if (!firm || !firm.firmAdmin) {
                    setFirmAdmin(false);
                    navigate('/dashboard');
                } else {
                    setFirmAdmin(true);
                }
                setPreLoader(false);
            }).catch(err => {
                handleError(err);
                navigate('/dashboard');
                setPreLoader(false);
            });
        }).catch((err) => {
            handleError(err);
            setPreLoader(false);
        });
    }, [dispatch, navigate, handleError]);

    const getFirmDetails = async () => {
        dispatch(getFirmById(companyData.companyID)).then(firmData => {
            setFirmDetails({
                name: firmData.name,
                active: firmData.active,
                email: firmData.email,
                phoneNumber: firmData.phoneNumber,
                vknNumber: firmData.vknNumber,
                parentFirmId: firmData.parentFirmId
            });
        }).catch(err => {
            handleError(err);
        });
    }

    useEffect(() => {
        if (isFirmAdmin) {
            getFirmDetails();
        }
    }, [isFirmAdmin]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFirmDetails(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!firmDetails.email || !emailPattern.test(firmDetails.email)) {
            sendNotify('Geçerli bir e-posta adresi girin.', 'danger');
            return;
        }

        const formData = {
            id: companyData.companyID,
            name: firmDetails.name,
            active: firmDetails.active,
            email: firmDetails.email,
            phoneNumber: firmDetails.phoneNumber,
            vknNumber: firmDetails.vknNumber,
            parentFirmId: firmDetails.parentFirmId
        };

        setLoading(true);
        dispatch(editFirm(formData)).then((response) => {
            sendNotify("Firma bilgileri güncellendi.", "success");
            getFirmDetails();
            setLoading(false);
        }).catch(err => {
            handleError(err);
            setLoading(false);
        });
    };

    return (
        <>
            {preLoader ? (
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            <BreadCrumb title={t("EditFirm_EditFirm")} pageTitle={t("AdminSettings")} pageLink="dashboard" />
                            <Col xs={12}>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className="mb-4">
                                                    <Col md={12}>
                                                        <h5 className="card-title">{t("EditFirm_AdliFirmayiDüzenle")} {firmDetails.name}</h5>
                                                    </Col>
                                                </Row>
                                                <Form onSubmit={handleSubmit}>
                                                    <FormGroup>
                                                        <Label for="name">{t("EditFirm_FirmaAdi")}</Label>
                                                        <Input
                                                            type="text"
                                                            id="name"
                                                            placeholder={t("EditFirm_FirmaAdi")}
                                                            value={firmDetails.name}
                                                            onChange={handleInputChange}
                                                            disabled
                                                            style={{ maxWidth: '500px' }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="email">{t("EditFirm_Email")}</Label>
                                                        <Input
                                                            type="email"
                                                            id="email"
                                                            placeholder={t("EditFirm_Email")}
                                                            value={firmDetails.email}
                                                            onChange={handleInputChange}
                                                            style={{ maxWidth: '500px' }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="phoneNumber">{t("EditFirm_TelefonNumarasi")}</Label>
                                                        <Input
                                                            type="text"
                                                            id="phoneNumber"
                                                            placeholder={t("EditFirm_TelefonNumarasi")}
                                                            value={firmDetails.phoneNumber}
                                                            onChange={handleInputChange}
                                                            style={{ maxWidth: '500px' }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="vknNumber">{t("EditFirm_VKN")}</Label>
                                                        <Input
                                                            type="number"
                                                            id="vknNumber"
                                                            placeholder={t("EditFirm_VKN")}
                                                            value={firmDetails.vknNumber}
                                                            onChange={handleInputChange}
                                                            style={{ maxWidth: '500px' }}
                                                        />
                                                    </FormGroup>
                                                    <Button color="primary" type="submit" disabled={loading || !firmDetails.phoneNumber || !firmDetails.email}>
                                                        {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                        {t("AdminSettings_Islemler_Duzenle")}
                                                    </Button>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default EditFirm;