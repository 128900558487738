import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../assets/scss/themes.scss';

const OnlineBanka = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    document.title = t("Online Banka") + " | Tanasoft";

    const menuItems = [
        { id: 'hesap-islemleri', title: t("Hesap İşlemleri"), icon: 'ri-bank-line', items: [
            { id: 'hesap-hareketleri', title: t("Hesap Hareketleri"), icon: 'ri-exchange-funds-line' },
        ]},
        { id: 'bakiye', title: t("Bakiye"), icon: 'ri-money-dollar-circle-line', items: [
            { id: 'bakiyeler', title: t("OnlineBanka_Bakiyeler"), icon: 'ri-funds-box-line' },
            { id: 'banka-bakiyeler', title: t("BankaBakiyeler"), icon: 'ri-bank-line' },
        ]},
        { id: 'raporlar', title: t("OnlineBanka_Raporlar"), icon: 'ri-file-chart-line', items: [
            { id: 'hesap-hareket-raporu', title: t("OnlineBanka_HesapHareketRaporu"), icon: 'ri-bank-card-line' },
        ]},
    ];

    const renderMenuItem = (item) => (
        <Card 
            key={item.id} 
            className="menu-item mb-2" 
            onClick={() => navigate(`/${item.id}`)}
            style={{ cursor: 'pointer', transition: 'all 0.3s ease'}}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
            <CardBody className="py-2 px-3" style={{ cursor: 'pointer' }}>
                <i className={`${item.icon} menu-icon me-2 fs-5`}></i>
                <span className="menu-title fs-6" style={{ cursor: 'pointer' }}>{item.title}</span>
            </CardBody>
        </Card>
    );

    const renderMenuSection = (section) => (
        <Col md={4} key={section.id} className="mb-4">
            <h5 className="section-title mb-3">
                <i className={`${section.icon} section-icon me-2 fs-4`}></i>
                {section.title}
            </h5>
            <Row className="g-2">
                {section.items.map(renderMenuItem)}
            </Row>
        </Col>
    );

    return (
        <div className="page-content online-banka">
            <Container fluid className="px-4">
                <h2 className="page-title mb-4 fs-2">{t("Online Banka")}</h2>
                <Row className="g-4">
                    {menuItems.map(renderMenuSection)}
                </Row>
            </Container>
        </div>
    );
};

export default OnlineBanka;