import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../Components/Common/withRouter';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import RightSidebar from '../Components/Common/RightSidebar';
import { changeLayout, changeSidebarTheme, changeLayoutMode, changeLayoutWidth, changeLayoutPosition, changeTopbarTheme, changeLeftsidebarSizeType, changeLeftsidebarViewType, changeSidebarImageType, changeSidebarVisibility } from "../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

const Layout = (props) => {
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            leftSidebarType: layout.leftSidebarType,
            layoutModeType: layout.layoutModeType,
            layoutWidthType: layout.layoutWidthType,
            layoutPositionType: layout.layoutPositionType,
            topbarThemeType: layout.topbarThemeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            leftSidebarViewType: layout.leftSidebarViewType,
            leftSidebarImageType: layout.leftSidebarImageType,
            preloader: layout.preloader,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
        })
    );

    const defaultStyles = {
        layout: 'vertical',
        layoutMode: 'light',
        sidebarTheme: 'dark',
        layoutWidth: 'lg',
        layoutPosition: 'fixed',
        topbarTheme: 'light',
        sidebarImageType: 'none',
        preloaderTypes: 'default',
        leftsidebarSizeType: 'lg',
        leftsidebarViewType: 'default',
        sidebarVisibility: 'show'
    };

    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        preloader,
        sidebarVisibilitytype
    } = useSelector(selectLayoutProperties);

    useEffect(() => {
        const themeSettings = JSON.parse(localStorage.getItem("themeSettings")) || {};
        const {
            layout = defaultStyles.layout,
            sidebarTheme = defaultStyles.sidebarTheme,
            layoutMode = defaultStyles.layoutMode,
            layoutWidth = defaultStyles.layoutWidth,
            layoutPosition = defaultStyles.layoutPosition,
            topbarTheme = defaultStyles.topbarTheme,
            leftsidebarSizeType = defaultStyles.leftsidebarSizeType,
            leftsidebarViewType = defaultStyles.leftsidebarViewType,
            sidebarImageType = defaultStyles.sidebarImageType,
            sidebarVisibility = defaultStyles.sidebarVisibility
        } = themeSettings;        

        dispatch(changeLayout(layout));
        dispatch(changeSidebarTheme(sidebarTheme));
        dispatch(changeLayoutMode(layoutMode));
        dispatch(changeLayoutWidth(layoutWidth));
        dispatch(changeLayoutPosition(layoutPosition));
        dispatch(changeTopbarTheme(topbarTheme));
        dispatch(changeLeftsidebarSizeType(leftsidebarSizeType));
        dispatch(changeLeftsidebarViewType(leftsidebarViewType));
        dispatch(changeSidebarImageType(sidebarImageType));
        dispatch(changeSidebarVisibility(sidebarVisibility));

        window.dispatchEvent(new Event('resize'));
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        sidebarVisibilitytype,
        dispatch
    ]);

    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        return () => window.removeEventListener("scroll", scrollNavigation);
    }, []);

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    useEffect(() => {
        const hamburgerIcon = document.querySelector(".hamburger-icon");
        if (sidebarVisibilitytype === 'show' || layoutType === "vertical" || layoutType === "twocolumn") {
            hamburgerIcon?.classList.remove('open');
        } else {
            hamburgerIcon?.classList.add('open');
        }
    }, [sidebarVisibilitytype, layoutType]);

    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar
                    layoutType={layoutType}
                />
                <div className="main-content">
                    {props.children}
                    <Footer />
                </div>
            </div>
            <RightSidebar />
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);