import axios from "axios";
import * as url from "../../../helpers/url_helper";

export const getUserDataFromAuth = () => async () => {
    try {
        const response = await axios.get(url.GET_PROFILEDATA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const changeUserInfo = (formData) => async () => {
    try {
        const response = await axios.put(url.PUT_PROFILEDATA, { firstName: formData.firstname, lastName: formData.lastname, password: formData.password, newPassword: formData.newPassword });
        
        if (response) {
            localStorage.removeItem("userData");
            localStorage.removeItem("companyData");
        }

        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enable2FA = () => async () => {
    try {
        const response = await axios.get(url.ENABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const disable2FA = () => async () => {
    try {
        const response = await axios.post(url.DISABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enable2FAConfirm = (code) => async () => {
    try {
        const response = await axios.get(url.VERIFY_2FA + '?AuthenticatorCode=' + code);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enableEmailAuth = () => async () => {
    try {
        const response = await axios.get(url.ENABLE_EMAILAUTH);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const disableEmailAuth = () => async () => {
    try {
        const response = await axios.post(url.DISABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};