import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { setAuthorization } from "../../../helpers/api_helper";
import { setEncryptedData, getDecryptedData } from "../../../helpers/utils";

export const loginUser = (user, authCode = null) => async () => {
    try {
        const userInfo = {
            email: user.email,
            password: user.password,
        };

        if (authCode)
            userInfo.authenticatorCode = authCode;

        const response = await axios.post(url.POST_LOGIN, userInfo);
        const userData = response.data;

        if (userData["requiredAuthenticatorType"] != "Otp" && userData["requiredAuthenticatorType"] != "Email") {
            setAuthorization(userData.accessToken["token"]);
            setEncryptedData("userData", JSON.stringify(userData));
        }

        return userData;
    } catch (err) {
        throw err;
    }
};

export const logoutUser = async (onSuccess) => {
    try {
        const userData = getDecryptedData("userData");

        if (!userData) {
            if (onSuccess) onSuccess();
            return;
        }

        const encodedToken = encodeURIComponent(userData.refreshToken["token"]);
        const response = await axios.put(`${url.POST_LOGOUT}?refreshToken=${encodedToken}`);

        if (response) {
            localStorage.removeItem("userData");
            localStorage.removeItem("companyData");
        }

        if (onSuccess) onSuccess();
    } catch (err) {
        if (onSuccess) onSuccess();
    }
};