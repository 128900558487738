import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Row, Col, Card, Container, Input, Label, Form, FormFeedback, Button, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { registerUser } from "../../slices/thunks";
import { useHandleError } from "../../helpers/utils";

const Register = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleError = useHandleError();
    const token = searchParams.get('Token');
    const email = searchParams.get('Email');
    const userWithEmail = searchParams.get('UserWithEmail');
    const [loading, setLoading] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: email,
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Lütfen e-posta adresinizi girin"),
            password: Yup.string()
                .min(8, 'Şifre en az 8 karakter olmalı')
                .matches(RegExp('(.*[a-z].*)'), 'En az bir küçük harf içermeli')
                .matches(RegExp('(.*[A-Z].*)'), 'En az bir büyük harf içermeli')
                .matches(RegExp('(.*[0-9].*)'), 'En az bir rakam içermeli')
                .required("Lütfen şifrenizi girin"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Şifreler eşleşmiyor")
                .required("Lütfen şifrenizi onaylayın"),
        }),
        onSubmit: (values) => {
            const userData = {
                email: values.email,
                password: values.password
            }

            setLoading(true);
            dispatch(registerUser(userData)).then(response => {
                setLoading(false);
                navigate(`/login?Token=${encodeURIComponent(token)}&Email=${email}&UserWithEmail=False`);
            }).catch(err => {
                handleError(err);
                setLoading(false);
            });
        }
    });
    document.title = "Hesap Oluştur | Tanasoft"

    useEffect(() => {
        if (userWithEmail === "True") {
            navigate(`/login?Token=${encodeURIComponent(token)}&Email=${encodeURIComponent(email)}&UserWithEmail=True`);
        } else if (!userWithEmail && !token && !email) {
            navigate(`/login`);
        }
    }, [navigate, token, email, userWithEmail]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container className="d-flex justify-content-center align-items-center">
                        <Row className="w-100 justify-content-center">
                            <Col lg={8}>
                                <Card className="overflow-hidden">
                                    <div className="p-lg-5 p-4">
                                        <div>
                                            <h5 className="text-primary">Hesap Oluştur</h5>
                                            <p className="text-muted">Tanasoft'a devam etmek için hesap oluşturun.</p>
                                        </div>

                                        <div className="mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#"
                                            >
                                                <div className="mb-3">
                                                    <Label htmlFor="userEmail" className="form-label">E-Posta</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="E-posta adresinizi girin"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        disabled
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="password-input">Şifre <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Şifrenizi girin"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            disabled={loading}
                                                        />

                                                        {validation.touched.password && validation.errors.password ? (
                                                            <div className="text-danger mt-2">{validation.errors.password}</div>
                                                        ) : null}

                                                        <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="confirmPassword">Şifreyi Onaylayın <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="confirm_password"
                                                            value={validation.values.confirm_password || ""}
                                                            type={confirmPasswordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Şifrenizi onaylayın"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            disabled={loading}
                                                        />

                                                        {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                            <div className="text-danger mt-2">{validation.errors.confirm_password}</div>
                                                        ) : null}

                                                        <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="confirm-password-addon">
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                    <h5 className="fs-13">Şifre aşağıdakileri içermelidir:</h5>
                                                    <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 karakter</b></p>
                                                    <p id="pass-lower" className="invalid fs-12 mb-2">En az bir <b>küçük harf</b> (a-z)</p>
                                                    <p id="pass-upper" className="invalid fs-12 mb-2">En az bir <b>büyük harf</b> (A-Z)</p>
                                                    <p id="pass-number" className="invalid fs-12 mb-0">En az bir <b>rakam</b> (0-9)</p>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit" disabled={loading || !validation.values.email || !validation.values.password || !validation.values.confirm_password}>
                                                        {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                        Kayıt Ol
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Tanasoft - Tüm Hakları Saklıdır.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default Register;