import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { logoutUser } from "../../slices/thunks";

const Logout = () => {
    const [isUserLogout, setIsUserLogout] = useState(false);

    useEffect(() => {
        logoutUser(() => setIsUserLogout(true));
    }, []);

    if (isUserLogout) {
        return <Navigate to="/login" />;
    }

    return null;
};

export default Logout;