import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getUserDataFromAuth, getFirmsByUserId, getInviteData, createInvite, deleteInvite } from "../../slices/thunks";
import { sendNotify, useHandleError } from "../../helpers/utils";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Table, FormGroup, Form, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../../assets/scss/themes.scss';
import Select from 'react-select';

const InviteUser = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    const navigate = useNavigate();
    document.title = t("AdminSettings_InviteUser") + " | Tanasoft";

    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const [isFirmAdmin, setFirmAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [inviteList, setInviteList] = useState([]);
    const [filteredInviteList, setFilteredInviteList] = useState([]);
    const [searchEmail, setSearchEmail] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [inviteUserPopup, setInviteUserPopup] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userIsFirmAdmin, setUserIsFirmAdmin] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const handleChangePage = (page) => setCurrentPage(page);
    const totalPages = Math.ceil(filteredInviteList.length / itemsPerPage);
    const displayedInviteList = filteredInviteList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const statusOptions = [...new Set(inviteList.map(invite => invite.status).filter(status => status))].map(name => {
        switch (name) {
            case "Failed":
                return { value: name, label: t("AdminSettings_InviteUser_Failed") };
            case "Successful":
                return { value: name, label: t("AdminSettings_InviteUser_Successful") };
            case "Pending":
                return { value: name, label: t("AdminSettings_InviteUser_Pending") };
            default:
                return { value: name, label: name };
        }
    });

    useEffect(() => {
        dispatch(getUserDataFromAuth()).then((authResponse) => {
            const userId = authResponse.id;
            dispatch(getFirmsByUserId(userId)).then(response => {
                const firm = response.find(firm => firm.firmId === companyData.companyID);
                if (!firm || !firm.firmAdmin) {
                    setFirmAdmin(false);
                    navigate('/dashboard');
                } else {
                    setFirmAdmin(true);
                }
                setPreLoader(false);
            }).catch(err => {
                handleError(err);
                navigate('/dashboard');
                setPreLoader(false);
            });
        }).catch((err) => {
            handleError(err);
            setPreLoader(false);
        });
    }, [dispatch, navigate, handleError]);

    useEffect(() => {
        if (isFirmAdmin) {
            fetchInviteData();
        }
    }, [isFirmAdmin]);    

    const fetchInviteData = async () => {
        setLoading(true);
        dispatch(getInviteData(companyData.companyID)).then((response) => {
            setInviteList(response);
            setFilteredInviteList(response);
            setLoading(false);
        }).catch(err => {
            handleError(err);
            setLoading(false);
        });
    };

    const formatDateString = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        let filtered = inviteList;

        if (searchEmail) {
            filtered = filtered.filter(invite => invite.email.toLowerCase().includes(searchEmail.toLowerCase()));
        }

        if (searchStatus) {
            filtered = filtered.filter(invite => invite.status.toLowerCase().includes(searchStatus.toLowerCase()));
        }

        setFilteredInviteList(filtered);
        setCurrentPage(1);
    }, [searchEmail, searchStatus, inviteList]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIds = displayedInviteList.map((_, index) => (currentPage - 1) * itemsPerPage + index);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    const handleDeleteInvite = (inviteId) => {
        dispatch(deleteInvite(inviteId)).then(() => {
            fetchInviteData();
            sendNotify('Davet başarıyla silindi.', 'success');
        }).catch((err) => {
            handleError(err);
        });
    };

    const handleRefresh = () => {
        fetchInviteData();
    };

    const handleStatusChange = (selectedOption) => {
        setSearchStatus(selectedOption ? selectedOption.value : '');
    };

    const handleInviteUserPopup = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!userEmail || !emailPattern.test(userEmail)) {
            sendNotify('Geçerli bir e-posta adresi girin.', 'danger');
            return;
        }

        const formData = {
            email: userEmail,
            firmId: companyData.companyID,
            firmAdmin: userIsFirmAdmin
        };

        setLoading(true);
        dispatch(createInvite(formData)).then(() => {
            fetchInviteData();
            sendNotify('Davet başarıyla gönderildi.', 'success');
            setUserEmail('');
            setUserIsFirmAdmin(false);
            setInviteUserPopup(false);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    return (
        <>
            {preLoader ? (
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            <BreadCrumb title={t("AdminSettings_InviteUser")} pageTitle={t("AdminSettings")} pageLink="dashboard" />
                            <Col xs={12}>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className="mb-4">
                                                    <Col md={12}>
                                                        <h5 className="card-title">{t("AdminSettings_InviteUser_Search")}</h5>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="searchEmail">{t("AdminSettings_InviteUser_Email")}</Label>
                                                            <Input type="text" id="searchEmail" placeholder={t("AdminSettings_InviteUser_SearchByEmail")} value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="searchStatus">{t("AdminSettings_InviteUser_Status")}</Label>
                                                            <Select
                                                                id="searchStatus"
                                                                options={statusOptions}
                                                                value={statusOptions.find(option => option.value === searchStatus)}
                                                                onChange={handleStatusChange}
                                                                placeholder={t("AdminSettings_InviteUser_SearchByStatus")}
                                                                isClearable
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <Button color="primary" className="d-flex justify-content-center align-items-center"
                                                            onClick={() => setInviteUserPopup(true)}>
                                                            <i className="bx bx-plus me-2" style={{ fontSize: "20px" }}></i>
                                                            <span className="text-capitalize">{t("AdminSettings_InviteUser_InviteUser")}</span>
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button color="primary" onClick={handleRefresh} disabled={loading}>
                                                            {loading ? <Spinner size="sm" className='me-2'>Yükleniyor...</Spinner> : null}
                                                            {t("AdminSettings_Islemler_Yenile")}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle table-nowrap table-hover">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th scope="col" style={{ width: "50px" }}>
                                                                    <div className="form-check">
                                                                        <Input className="form-check-input" type="checkbox" id="checkAll" value="option" onChange={handleSelectAll} checked={selectedItems.length === displayedInviteList.length} />
                                                                    </div>
                                                                </th>
                                                                <th className="text-capitalize">{t("AdminSettings_InviteUser_Email")}</th>
                                                                <th className="text-capitalize">{t("AdminSettings_InviteUser_IsFirmAdmin")}</th>
                                                                <th className="text-capitalize">{t("AdminSettings_InviteUser_Status")}</th>
                                                                <th className="text-capitalize">{t("AdminSettings_InviteUser_ExpirationDate")}</th>
                                                                <th className="text-capitalize">{t("AdminSettings_InviteUser_ActionDate")}</th>
                                                                <th className="text-capitalize">{t("AdminSettings_Islemler")}</th>
                                                            </tr>
                                                        </thead>
                                                        {displayedInviteList.length >= 1 && (
                                                            <tbody>
                                                                {displayedInviteList.map((invite, index) => {
                                                                    const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                                                    const isChecked = selectedItems.includes(globalIndex);
                                                                    return (
                                                                        <tr key={index} className={isChecked ? "table-active" : ""}>
                                                                            <td>
                                                                                <div className="form-check">
                                                                                    <Input className="form-check-input" type="checkbox" name="chk_child" value="option1" checked={isChecked} onChange={() => handleSelectItem(globalIndex)} />
                                                                                </div>
                                                                            </td>
                                                                            <td className="text">{invite.email}</td>
                                                                            <td className="text">{invite.firmAdmin ? t("AdminSettings_InviteUser_Yes") : t("AdminSettings_InviteUser_No")}</td>
                                                                            <td className="text">
                                                                                <div className={`${invite.status === "Pending" ? "text-warning" : invite.status === "Failed" ? "text-danger" : "text-success"} ${invite.status.length > 10 ? "w-500" : ""}`}>
                                                                                    {invite.status === "Pending" ? t("AdminSettings_InviteUser_Pending") : invite.status === "Failed" ? t("AdminSettings_InviteUser_Failed") : t("AdminSettings_InviteUser_Successful")}
                                                                                </div>
                                                                            </td>
                                                                            <td className="text">{formatDateString(invite.expirationDate)}</td>
                                                                            <td className="text">{formatDateString(invite.actionDate) || t("AdminSettings_InviteUser_NotUsed")}</td>
                                                                            <td>
                                                                                <Button color="danger" onClick={() => handleDeleteInvite(invite.id)}>{t("AdminSettings_InviteUser_Delete")}</Button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>)}
                                                    </Table>

                                                    {displayedInviteList.length <= 0 && (
                                                        <Row>
                                                            <div style={{ width: "100%", height: 45 }} className="d-flex justify-content-center align-items-center border-2 border-bottom">
                                                                Kayıt Bulunamadı
                                                            </div>
                                                        </Row>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="text-muted">
                                                        {t("SelectCompanyPopup_Records")} {((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, inviteList.length)} {t("SelectCompanyPopup_Of")} ({inviteList.length})
                                                    </div>
                                                    <div>
                                                        <ul className="pagination mb-0">
                                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                                <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>{t("AdminSettings_Islemler_Onceki")}</Link>
                                                            </li>
                                                            {[...Array(totalPages)].map((_, pageIndex) => (
                                                                <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                                                    <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                                                </li>
                                                            ))}
                                                            <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                                                                <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>{t("AdminSettings_Islemler_Sonraki")}</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </div>

                    <Modal isOpen={inviteUserPopup} toggle={() => setInviteUserPopup(!inviteUserPopup)} onSubmit={handleInviteUserPopup}>
                        <ModalHeader toggle={() => setInviteUserPopup(false)}>{t("AdminSettings_InviteUser_InviteUser")}</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleInviteUserPopup}>
                                <FormGroup>
                                    <Label for="userEmail">{t("AdminSettings_InviteUser_Email")}</Label>
                                    <Input type="email" id="userEmail" placeholder={t("AdminSettings_InviteUser_Email")} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} autoComplete="new-password" required />
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" id="userIsFirmAdmin" checked={userIsFirmAdmin} onChange={(e) => setUserIsFirmAdmin(e.target.checked)} />{' '}
                                        {t("AdminSettings_InviteUser_IsFirmAdmin")}
                                    </Label>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={handleInviteUserPopup} type="submit" disabled={loading || !userEmail}>
                                {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                {t("AdminSettings_InviteUser_SendInvitation")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </React.Fragment>
            )}
        </>
    );
};

export default InviteUser;