import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, ModalFooter, Button, Input, Spinner, Label } from 'reactstrap';
import { useTranslation } from "react-i18next";

const SetUserDetailsPopup = ({ isOpen, toggle, onConfirm, loading }) => {
    const { t } = useTranslation();
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');

    const handleConfirm = () => {
        onConfirm(firstName, lastName);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Kullanıcı Bilgilerini Belirle</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleConfirm}>
                    <FormGroup>
                        <Label for="userFirstName">Ad</Label>
                        <Input type="text" id="userFirstName" placeholder="Adınızı girin" value={firstName} onChange={(e) => setFirstname(e.target.value)} autoComplete="new-password" required />
                    </FormGroup>
                    <FormGroup>
                        <Label for="userLastName">Soyad</Label>
                        <Input type="text" id="userLastName" placeholder="Soyadınızı girin" value={lastName} onChange={(e) => setLastname(e.target.value)} autoComplete="new-password" required />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirm} disabled={loading}>
                    {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                    Onayla
                </Button>
            </ModalFooter>
        </Modal>
    );    
};

export default SetUserDetailsPopup;