import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Spinner } from 'reactstrap';
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";

const Enable2FAPopup = ({ isOpen, toggle, onConfirm, value, loading }) => {
    const { t } = useTranslation();
    const QRUrl = value?.otpQrUrl || '';
    const secretKey = value?.secretKey || '';
    const [code, setCode] = useState('');

    const handleInputChange = (e) => {
        setCode(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(code);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{t("Popup_2FA")}</ModalHeader>
            <ModalBody className="text-center">
                <QRCode value={QRUrl} />
                <p className="text-muted mt-2" style={{ fontSize: 'small' }}>{t("Popup_2FASecretKey")} {secretKey}</p>
                <Input
                    type="text"
                    placeholder={t("Popup_2FAEnterCode")}
                    className="mt-3"
                    value={code}
                    onChange={handleInputChange}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirm} disabled={loading}>
                    {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                    {t("Popup_2FAConfirm")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Enable2FAPopup;