import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserDataFromAuth, getFirmBankAccountsByUserId } from "../../../slices/thunks";
import { Col, Card, CardBody, Container, Row, Spinner } from 'reactstrap';
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

const OnlineBakiyeChart = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const [loading, setLoading] = useState(false);
    const [balanceData, setBalanceData] = useState({});
    const [hasData, setHasData] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchBalanceData() {
            try {
                const userData = await dispatch(getUserDataFromAuth());
                const response = await dispatch(getFirmBankAccountsByUserId(userData.id, true));
                const firmBankAccounts = response.filter(account => account.firmId === companyData.companyID);
                const groupedData = groupByCurrency(firmBankAccounts);

                setBalanceData(groupedData);
                setHasData(Object.keys(groupedData).length > 0);
            } catch (error) {
                setHasData(false);
            } finally {
                setLoading(false);
            }
        }

        fetchBalanceData();
    }, []);

    const groupByCurrency = (data) => {
        const grouped = {};
        data.forEach(item => {
            const { currencyType, balance, blockedBalance, bankName } = item;
            if (!grouped[currencyType]) {
                grouped[currencyType] = {
                    totalBalance: 0,
                    totalBlockedBalance: 0,
                    banks: new Set(),
                    accounts: 0
                };
            }
            grouped[currencyType].totalBalance += balance;
            grouped[currencyType].totalBlockedBalance += blockedBalance;
            grouped[currencyType].banks.add(bankName);
            grouped[currencyType].accounts += 1;
        });
        return grouped;
    };

    const renderCard = (currencyType) => {
        const { totalBalance, totalBlockedBalance, banks, accounts } = balanceData[currencyType] || {};

        return (
            <>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{currencyType}</p>
                            </div>
                            <div className="flex-shrink-0">
                                <p className={"fw-medium mb-0 text-muted"}>
                                    {t("Dashboard_OnlineBakiyeChart_BankCount")} {banks ? banks.size : 0} - {t("Dashboard_OnlineBakiyeChart_AccountCount")} {accounts || 0}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary text-success">
                                    <CountUp
                                        start={0}
                                        end={totalBalance}
                                        decimals={2}
                                        separator=","
                                        duration={1}
                                        formattingFn={(value) => value.toLocaleString()}
                                    />
                                    {" "}{currencyType}
                                </h4>
                                <h4 className="fs-20 fw-semibold ff-secondary text-danger">
                                    <CountUp
                                        start={0}
                                        end={totalBlockedBalance}
                                        decimals={2}
                                        separator=","
                                        duration={1}
                                        formattingFn={(value) => value.toLocaleString()}
                                    />
                                    {" "}{currencyType}
                                </h4>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    };

    return (
        loading ? (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner color="primary" />
            </div>
        ) : (
            <Container fluid>
                <Row style={{
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    flexWrap: "nowrap",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                    "&::WebkitScrollbar": { display: "none" },
                    userSelect: "none"
                }}
                    onMouseDown={(e) => {
                        const row = e.currentTarget;
                        let startX = e.pageX - row.offsetLeft;
                        let scrollLeft = row.scrollLeft;

                        const onMouseMove = (e) => {
                            const x = e.pageX - row.offsetLeft;
                            const walk = (x - startX) * 1;
                            row.scrollLeft = scrollLeft - walk;
                        };

                        const onMouseUp = () => {
                            row.removeEventListener('mousemove', onMouseMove);
                            row.removeEventListener('mouseup', onMouseUp);
                            row.style.cursor = "grab";
                        };

                        row.addEventListener('mousemove', onMouseMove);
                        row.addEventListener('mouseup', onMouseUp);
                        row.style.cursor = "grabbing";
                    }}>
                    {Object.keys(balanceData).map((currencyType) => (
                        <Col xl={3} md={6} key={currencyType} style={{ display: "inline-block", float: "none" }}>
                            {renderCard(currencyType === "TANIMSIZ" && i18next.language === "en" ? t("Dashboard_OnlineBakiyeChart_Undefined") : currencyType)}
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    );
};

export default OnlineBakiyeChart;