import flagturkish from "../assets/images/flags/tr.svg";
import flagus from "../assets/images/flags/us.svg";

const languages = {
    tr: {
        label: "Türkçe",
        flag: flagturkish,
    },
    en: {
        label: "English",
        flag: flagus,
    },
}

export default languages