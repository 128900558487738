import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Spinner } from 'reactstrap';
import { useTranslation } from "react-i18next";

const AuthCodePopup = ({ isOpen, toggle, onConfirm, loading }) => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');

    const handleInputChange = (e) => {
        setCode(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(code);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{t("Popup_2FA")}</ModalHeader>
            <ModalBody className="text-center">
                <Input type="text" placeholder={t("Popup_2FAEnterCode")} className="mt-3" value={code} onChange={handleInputChange} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleConfirm} disabled={loading}>
                    {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                    {t("Popup_2FAConfirm")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AuthCodePopup;