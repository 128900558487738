import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import * as url from '../../../helpers/url_helper';
import { Col, FormGroup, Input, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const IslemTipineGoreChart = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [chartData, setChartData] = useState(null);
    const [dateRange, setDateRange] = useState('daily');
    const [currency, setCurrency] = useState('TRY');
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hasData, setHasData] = useState(true);
    const chartRef = useRef(null);

    const dateRangeOptions = [
        { value: 'daily', label: t("Dashboard_IslemTipineGoreHareketler_Gelen_Daily") },
        { value: 'monthly', label: t("Dashboard_IslemTipineGoreHareketler_Gelen_Monthly") },
        { value: 'yearly', label: t("Dashboard_IslemTipineGoreHareketler_Gelen_Yearly") }
    ];

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const calculateDateRange = (rangeType) => {
        const now = new Date();
        let startDate;
        switch (rangeType) {
            case 'daily':
                startDate = new Date(now.setDate(now.getDate() - 1));
                break;
            case 'monthly':
                startDate = new Date(now.setMonth(now.getMonth() - 1));
                break;
            case 'yearly':
                startDate = new Date(now.setFullYear(now.getFullYear() - 1));
                break;
            default:
                startDate = new Date(now.setDate(now.getDate() - 1));
        }
        return startDate;
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                const todayDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                };

                const startDate = calculateDateRange(dateRange);

                const response = await axios.post(url.GET_BANK_TRANSACTIONS, {
                    "firms": [companyData.companyID],
                    "banks": [],
                    "bankBranches": [],
                    "firmBankAccounts": [],
                    "dynamicQuery": {
                        "filter": {
                            "field": "date",
                            "operator": "GreaterThanOrEqual",
                            "value": todayDate(startDate),
                            "logic": "and",
                            "filters": [
                                {
                                    "field": "amount",
                                    "operator": "GreaterThan",
                                    "value": "0"
                                }
                            ]
                        },
                        "sort": [
                            {
                                "field": "date",
                                "dir": "Descending"
                            }
                        ]
                    }
                });

                let responseData = response.data.items;

                const normalizeCurrency = (currencyType) => {
                    return currencyType === 'TL' ? 'TRY' : currencyType;
                };

                const uniqueCurrencies = Array.from(new Set(responseData.map(item => normalizeCurrency(item.currencyType))));
                setCurrencyOptions(uniqueCurrencies.map(curr => ({ value: curr, label: curr })));

                const filteredTransactions = responseData
                    .map(item => ({ ...item, currencyType: normalizeCurrency(item.currencyType) }))
                    .filter(item => item.currencyType === currency);

                const aggregatedData = filteredTransactions.reduce((acc, item) => {
                    let transactionType = item.transactionType;
                    if (!acc[transactionType]) {
                        acc[transactionType] = 0;
                    }
                    acc[transactionType] += item.amount;
                    return acc;
                }, {});

                const labels = Object.keys(aggregatedData);
                const amounts = Object.values(aggregatedData);

                if (amounts.length === 0 || amounts.every(amount => amount === 0)) {
                    setHasData(false);
                    setChartData(null);
                } else {
                    setHasData(true);
                    const totalAmount = amounts.reduce((sum, val) => sum + val, 0);
                    const percentages = amounts.map(amount => (amount / totalAmount) * 100);

                    setChartData({
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b} : {c} ({d}%)',
                            position: 'top'
                        },
                        legend: {
                            orient: 'horizontal',
                            bottom: 0,
                            textStyle: {
                                color: '$form-text-color'
                            }
                        },
                        series: [
                            {
                                name: t("Dashboard_IslemTipineGoreHareketler_Gelen_IslemTipi"),
                                type: 'pie',
                                radius: ['20%', '70%'],
                                center: ['50%', '36%'],
                                roseType: 'radius',
                                itemStyle: {
                                    borderRadius: 5,
                                },
                                label: {
                                    show: false
                                },
                                emphasis: {
                                    label: {
                                        show: false
                                    }
                                },
                                data: labels.map((label, index) => ({
                                    value: Number(percentages[index].toFixed(2)),
                                    name: label
                                }))
                            }
                        ]
                    });
                }
            } catch (error) {
                console.error('API çağrısı sırasında bir hata oluştu:', error.response ? error.response.data : error.message);
                setHasData(false);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [dateRange, currency]);

    const handleDropdownAction = (action) => {
        if (action === 'download') {
            downloadChart();
        } else if (action === 'excel') {
            exportToExcel();
        }
    };

    const exportToExcel = () => {
        if (!chartData || !chartData.series || !chartData.series[0].data) {
            console.warn("No data to export");
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(chartData.series[0].data.map(item => ({
            TransactionType: item.name,
            Percentage: item.value,
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Chart Data');

        XLSX.writeFile(workbook, 'chart_data.xlsx');
    };

    const downloadChart = () => {
        if (chartRef.current) {
            const chart = chartRef.current.getEchartsInstance();
            const imgData = chart.getDataURL({
                type: 'png',
                pixelRatio: 2,
                backgroundColor: '#fff'
            });

            const link = document.createElement('a');
            link.href = imgData;
            link.download = 'chart_image.png';
            link.click();
        }
    };

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner color="primary" />
                </div>
            ) : (
                <React.Fragment>
                    <Row className="align-items-center mb-3">
                        <Col xs={6} sm={6} md={4}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="select"
                                    id="dateRangeSelect"
                                    value={dateRange}
                                    onChange={(e) => setDateRange(e.target.value)}
                                >
                                    {dateRangeOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        {hasData && (
                            <Col xs={6} sm={6} md={4}>
                                <FormGroup>
                                    <Input
                                        type="select"
                                        name="select"
                                        id="currencySelect"
                                        value={currency}
                                        onChange={(e) => setCurrency(e.target.value)}
                                    >
                                        {currencyOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        )}
                        {hasData && (
                            <Col xs={6} sm={6} md={4}>
                                <FormGroup style={{ textAlign: 'right' }}>
                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                                        <DropdownToggle caret color="primary" >
                                            {t("Dashboard_IslemTipineGoreHareketler_Gelen_Eylemler")}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => handleDropdownAction('download')}>{t("Dashboard_IslemTipineGoreHareketler_Gelen_Download")}</DropdownItem>
                                            <DropdownItem onClick={() => handleDropdownAction('excel')}>{t("Dashboard_IslemTipineGoreHareketler_Gelen_Excel")}</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                    {hasData && chartData && (
                        <ReactECharts
                            ref={chartRef}
                            option={chartData}
                            style={{ height: '400px', width: '100%' }}
                        />
                    )}
                    {!hasData && (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                            <h5>{t("Dashboard_NoData")}</h5>
                        </div>
                    )}
                </React.Fragment>
            )}
        </>
    );
};

export default IslemTipineGoreChart;
