import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getUserDataFromAuth } from "../../slices/thunks";
import SelectCompanyPopup from "../../Components/Common/SelectCompanyPopup";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../slices/thunks';

const ProfileDropdown = () => {
    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const [openCompanyPopup, setCompanyPopup] = useState(false);
    const [showFirmSelection, setShowFirmSelection] = useState(false);

    useEffect(() => {
        if (!companyData.companyID) {
            navigate('/login');
        }
    }, [navigate, companyData.companyID]);

    useEffect(() => {
        dispatch(getUserDataFromAuth()).then(response => {
            setFirstName(response["firstName"] || t("Unknown"));
            setLastName(response["lastName"] || t("Unknown"));

            if (companyData.canSelectFirm)
                setShowFirmSelection(true);
            else 
                setShowFirmSelection(false);

            if (response.roles && response.roles.length > 0)
                setUserRole(response.roles[0].roleName);
        }).catch(error => {
            console.error("Kullanıcı bilgisi alınırken bir hata oluştu:", error);
        });
    }, [t, companyData.canSelectFirm, dispatch]);

    const handleCompanyPopupConfirm = () => {
        setCompanyPopup(false);
        navigate('/dashboard');
    };

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handleFirmSelection = () => {
        setCompanyPopup(true);
    };

    const handleThemeSettings = () => {
        dispatch(toggleSidebar());
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${firstName}${lastName}`} alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{firstName} {lastName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userRole}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">{t("ProfileDropdown_Welcome")} {firstName} {lastName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to="/profile" className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">{t("ProfileDropdown_Profile")}</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link className="dropdown-item" onClick={handleThemeSettings}>
                            <i className="mdi mdi-cog text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">{t("ProfileDropdown_ThemeSettings")}</span>
                        </Link>
                    </DropdownItem>

                    {showFirmSelection && (
                        <>
                            <div className="dropdown-divider"></div>
                            <DropdownItem className='p-0'>
                                <Link className="dropdown-item" onClick={handleFirmSelection}>
                                    <i className="mdi mdi-swap-horizontal text-muted fs-16 align-middle me-1"></i>
                                    <span className="align-middle">{t("ProfileDropdown_FirmSelection")}</span>
                                </Link>
                            </DropdownItem>
                        </>
                    )}

                    <SelectCompanyPopup isOpen={openCompanyPopup} toggle={() => setCompanyPopup(!openCompanyPopup)} onConfirm={handleCompanyPopupConfirm} source={"asd"} />
                    <div className="dropdown-divider"></div>
                    <DropdownItem className='p-0'>
                        <Link to="/logout" className="dropdown-item">
                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle" data-key="t-logout">{t("ProfileDropdown_Logout")}</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;