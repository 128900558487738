import axios from "axios";
import * as url from "../../../helpers/url_helper";

export const getTransactions = (firmId) => async () => {
    try {
        const response = await axios.post(url.GET_BANK_TRANSACTIONS, {
            "firms": [firmId],
            "banks": [],
            "bankBranches": [],
            "firmBankAccounts": [],
            "dynamicQuery": {
                // "filter": {
                //     "field": "date",
                //     "operator": "Equals",
                //     "value": "5e021ce1-415b-4767-4063-08dcc36f58dd"
                // },
                "sort": [
                    {
                        "field": "date",
                        "dir": "Descending"
                    }
                ]
            }
        });
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const getBankBranch = (branchId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK_BRANCH + branchId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBank = (bankId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK + bankId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBankAccount = (bankAccountId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK_ACCOUNT + bankAccountId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBalanceWithBanks = (firmId) => async () => {
    try {
        const response = await axios.post(url.GET_BALANCE_WITH_BANKS, {
            "firmBankAccounts": [], //firma banka hesabı id'leri
            "firms": [firmId], //firma id'leri
            "currencyType": "" //boş verilirse veya hiç verilmezse bütün kurları getirir, dolu ise sadece verilen kuru geitir
        });
        return response.data;
    } catch (err) {
        throw err;
    }
};