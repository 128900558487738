import { changeHTMLAttribute } from './utils';
import {
    changeLayoutAction,
    changeLayoutModeAction,
    changeSidebarThemeAction,
    changeLayoutWidthAction,
    changeLayoutPositionAction,
    changeTopbarThemeAction,
    changeLeftsidebarSizeTypeAction,
    changeLeftsidebarViewTypeAction,
    changeSidebarImageTypeAction,
    changePreLoaderAction,
    changeSidebarVisibilityAction,
    toggleSidebarAction
} from './reducer';

const defaultStyles = {
    layout: 'vertical',
    layoutMode: 'light',
    sidebarTheme: 'light',
    layoutWidth: 'lg',
    layoutPosition: 'fixed',
    topbarTheme: 'dark',
    sidebarImageType: 'none',
    preloaderTypes: 'default',
    leftsidebarSizeType: 'lg',
    leftsidebarViewType: 'default',
    sidebarVisibility: 'show'
};

const updateThemeSettings = (updates) => {
    let themeSettings = JSON.parse(localStorage.getItem("themeSettings")) || defaultStyles;
    themeSettings = { ...themeSettings, ...updates };
    localStorage.setItem("themeSettings", JSON.stringify(themeSettings));
};  

export const changeLayout = (layout) => async (dispatch) => {
    try {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        } else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        } else if (layout === "semibox") {
            changeHTMLAttribute("data-layout-width", "fluid");
            changeHTMLAttribute("data-layout-style", "default");
        }
        changeHTMLAttribute("data-layout", layout);
        dispatch(changeLayoutAction(layout));
        updateThemeSettings({ layout });
    } catch (error) { }
};

export const changeLayoutMode = (layoutMode) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-bs-theme", layoutMode);
        dispatch(changeLayoutModeAction(layoutMode));
        updateThemeSettings({ layoutMode });
    } catch (error) { }
};

export const changeSidebarTheme = (theme) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-sidebar", theme);
        dispatch(changeSidebarThemeAction(theme));
        updateThemeSettings({ sidebarTheme: theme });
    } catch (error) { }
};

export const changeLayoutWidth = (layoutWidth) => async (dispatch) => {
    try {
        if (layoutWidth === 'lg') {
            changeHTMLAttribute("data-layout-width", "fluid");
        } else {
            changeHTMLAttribute("data-layout-width", "boxed");
        }
        dispatch(changeLayoutWidthAction(layoutWidth));
        updateThemeSettings({ layoutWidth });
    } catch (error) { }
};

export const changeLayoutPosition = (layoutPosition) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-layout-position", layoutPosition);
        dispatch(changeLayoutPositionAction(layoutPosition));
        updateThemeSettings({ layoutPosition });
    } catch (error) { }
};

export const changeTopbarTheme = (topbarTheme) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-topbar", topbarTheme);
        dispatch(changeTopbarThemeAction(topbarTheme));
        updateThemeSettings({ topbarTheme });
    } catch (error) { }
};

export const changeSidebarImageType = (sidebarImageType) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-sidebar-image", sidebarImageType);
        dispatch(changeSidebarImageTypeAction(sidebarImageType));
        updateThemeSettings({ sidebarImageType });
    } catch (error) { }
};

export const changePreLoader = (preloaderTypes) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-preloader", preloaderTypes);
        dispatch(changePreLoaderAction(preloaderTypes));
        updateThemeSettings({ preloaderTypes });
    } catch (error) { }
};

export const changeLeftsidebarSizeType = (leftsidebarSizeType) => async (dispatch) => {
    try {
        switch (leftsidebarSizeType) {
            case 'lg':
                changeHTMLAttribute("data-sidebar-size", "lg");
                break;
            case 'md':
                changeHTMLAttribute("data-sidebar-size", "md");
                break;
            case "sm":
                changeHTMLAttribute("data-sidebar-size", "sm");
                break;
            case "sm-hover":
                changeHTMLAttribute("data-sidebar-size", "sm-hover");
                break;
            default:
                changeHTMLAttribute("data-sidebar-size", "lg");
        }
        dispatch(changeLeftsidebarSizeTypeAction(leftsidebarSizeType));
        updateThemeSettings({ leftsidebarSizeType });
    } catch (error) { }
};

export const changeLeftsidebarViewType = (leftsidebarViewType) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-layout-style", leftsidebarViewType);
        dispatch(changeLeftsidebarViewTypeAction(leftsidebarViewType));
        updateThemeSettings({ leftsidebarViewType });
    } catch (error) { }
};

export const changeSidebarVisibility = (sidebarVisibilityType) => async (dispatch) => {
    try {
        changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilityType);
        dispatch(changeSidebarVisibilityAction(sidebarVisibilityType));
        updateThemeSettings({ sidebarVisibility: sidebarVisibilityType });
    } catch (error) { }
};

export const toggleSidebar = () => async (dispatch) => {
    try {
        dispatch(toggleSidebarAction());
    } catch (error) { }
}