import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { actions } from "react-table";

export const getUsers = (firmId) => async () => {
    try {
        const response = await axios.get(url.GET_USERS + `&FirmId=${firmId}`);
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const addUser = (formData) => async () => {
    try {
        const response = await axios.post(url.CREATE_USER, formData);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const registerUser = (formData) => async () => {
    try {
        const response = await axios.post(url.REGISTER_USER_BY_EMAIL, formData);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export const updateUser = (formData) => async () => {
    try {
        const response = await axios.put(url.UPDATE_USER, formData);
        return response.data;
    } catch (err) {
        throw err;
    }
}

export const editUser = (formData) => async () => {
    try {
        const userUpdateData = {
            id: formData.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            password: formData.password
        };
        await axios.put(url.UPDATE_USER, userUpdateData);

        const createUserFirmBankAccountMatchDtos = formData.bankAccounts
            .filter(account => account.action === 'add')
            .map(account => ({
                userFirmMatchId: formData.userFirmMatchId,
                firmBankAccountId: account.firmBankAccountId,
                SignType: "Both"
            }));

        if (createUserFirmBankAccountMatchDtos.length > 0) {
            await axios.post(url.ADD_USER_BANK_ACCOUNT, { createUserFirmBankAccountMatchDtos });
        }

        const accountsToRemove = formData.bankAccounts
            .filter(account => account.action === 'remove')
            .map(account => ({ id: account.id }));
        
        if (accountsToRemove.length > 0) {
            await axios.delete(url.REMOVE_USER_BANK_ACCOUNT, { data: accountsToRemove });
        }

        const createUserFirmMatchDtos = formData.subFirms
            .filter(firm => firm.action === 'add')
            .map(firm => ({
                userId: formData.id,
                firmId: firm.firmId,
                firmAdmin: firm.firmAdmin
            }));

        if (createUserFirmMatchDtos.length > 0) {
            await axios.post(url.ADD_USER_FIRM, { createUserFirmMatchDtos });
        }

        const updateUserFirmMatchDtos = formData.subFirms
            .filter(firm => firm.action === 'update')
            .map(firm => ({ 
                id: firm.id, 
                firmAdmin: firm.firmAdmin 
            }));

        if (updateUserFirmMatchDtos.length > 0) {
            await axios.put(url.UPDATE_USER_FIRM, { updateUserFirmMatchDtos });
        }

        const firmsToRemove = formData.subFirms
            .filter(firm => firm.action === 'remove')
            .map(firm => ({ id: firm.id }));

        if (firmsToRemove.length > 0) {
            await axios.delete(url.REMOVE_USER_FIRM, { data: firmsToRemove });
        }

        return { success: true };
    } catch (err) {
        throw err;
    }
};

export const deleteUser = (userId) => async () => {
    try {
        const response = await axios.delete(url.DELETE_USER, { data: { id: userId } });
        return response.data;
    } catch (err) {
        throw err;        
    }
};

export const addUserToFirm = (userId, firmId, firmAdmin) => async () => {
    try {
        const createUserFirmMatchDtos = [{
            userId: userId,
            firmId: firmId,
            firmAdmin: firmAdmin
        }];
        const response = await axios.post(url.ADD_USER_FIRM, { createUserFirmMatchDtos });
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const addUserToFirmByInvite = (inviteToken) => async () => {
    try {
        const response = await axios.post(url.ADD_USER_FIRM_BY_INVITE, { token: inviteToken });
        return response.data;
    } catch (err) {
        throw err;
    }
}

export const getFirmById = (companyId) => async () => {
    try {
        const response = await axios.get(url.GET_FIRM_BY_ID + companyId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const editFirm = (formData) => async () => {
    try {
        const response = await axios.put(url.UPDATE_FIRM, formData);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getInviteData = (companyId) => async () => {
    try {
        const response = await axios.get(url.GET_INVITE_DATA + companyId + '?PageIndex=0&PageSize=2000');
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const createInvite = (formData) => async () => {
    try {
        const response = await axios.post(url.CREATE_INVITE, formData);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const deleteInvite = (inviteId) => async () => {
    try {
        const response = await axios.delete(url.DELETE_INVITE + inviteId);
        return response.data;
    } catch (err) {
        throw err;
    }
};