import React from 'react';
import Route from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/themes.scss';
import 'chart.js/auto';

function App() {
    return (
        <React.Fragment>
            <Route />
            <ToastContainer />
        </React.Fragment>
    );
}

export default App;