import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';

const selectLayoutMode = createSelector(
    (state) => state.Layout,
    (layout) => ({
        layoutModeType: layout.layoutModeType
    })
);

const NonAuthLayout = ({ children }) => {
    const { layoutModeType } = useSelector(selectLayoutMode);

    useEffect(() => {
        try {
            const themeSettings = JSON.parse(localStorage.getItem("themeSettings"));
            if (themeSettings) {
                const theme = themeSettings["layoutMode"] || "light";
                document.body.setAttribute("data-bs-theme", theme);
            } else {
                document.body.setAttribute("data-bs-theme", "light");
            }
        } catch (error) {
            document.body.setAttribute("data-bs-theme", "light");
        }
    
        return () => {
            document.body.removeAttribute("data-bs-theme");
        };
    }, [layoutModeType]);

    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);