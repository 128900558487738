import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getTransactions } from "../../../slices/thunks";
import { useHandleError } from "../../../helpers/utils";
import { Card, CardBody, Col, Container, Input, Row, Table, Label, Button } from 'reactstrap';
import Select from 'react-select';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';

const HesapHareketleri = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    document.title = t("Hesap Hareketleri") + " | Tanasoft";

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedItems, setSelectedItems] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const totalPages = filteredTransactions.length > 0 && itemsPerPage > 0 ? Math.ceil(filteredTransactions.length / itemsPerPage) : 1;
    const displayedTransactions = filteredTransactions.slice((currentPage - 1) * (itemsPerPage > 0 ? itemsPerPage : 1), currentPage * (itemsPerPage > 0 ? itemsPerPage : 1));
    const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');
    const [bankName, setBankName] = useState(null);
    const [bankBranch, setBankBranch] = useState(null);
    const [description, setDescription] = useState('');
    const [receiptNumber, setReceiptNumber] = useState('');
    const [currencyType, setCurrencyType] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [senderVKN, setSenderVKN] = useState('');
    const [receiverVKN, setReceiverVKN] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const bankNameSelectOptions = [...new Set(transactions.map(transactions => transactions.bankName).filter(name => name))].map(name => ({ value: name, label: name }));
    const bankBranchSelectOptions = [...new Set(transactions.map(transactions => transactions.branchName).filter(name => name))].map(name => ({ value: name, label: name }));
    const accountTypeSelectOptions = [...new Set(transactions.map(transactions => transactions.bankAccountType).filter(name => name))].map(name => ({ value: name, label: name }));
    const currencyTypeSelectOptions = [...new Set(transactions.map(transactions => transactions.currencyType).filter(name => name))].map(name => ({ value: name, label: name }));

    const handleChangePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const fetchTransactions = async () => {
        const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
        dispatch(getTransactions(companyData.companyID)).then((response) => {
            setTransactions(response);
        }).catch((err) => {
            handleError(err);
        });
    };

    const formatDateString = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    useEffect(() => {
        const filters = {
            date: (hareket) => {
                const hareketDate = formatDateString(hareket.date);
                return (!startDate || hareketDate >= formatDateString(startDate)) &&
                    (!finishDate || hareketDate <= formatDateString(finishDate));
            },
            bankName: (hareket) => !bankName || hareket.bankName === bankName,
            branchName: (hareket) => !bankBranch || hareket.branchName === bankBranch,
            description: (hareket) => !description || hareket.description.includes(description),
            receiptNumber: (hareket) => !receiptNumber || hareket.receiptNumber === receiptNumber,
            currencyType: (hareket) => !currencyType || hareket.currencyType === currencyType,
            bankAccountType: (hareket) => !accountType || hareket.bankAccountType === accountType,
            senderVKN: (hareket) => !senderVKN || hareket.senderVKN === senderVKN,
            receiverVKN: (hareket) => !receiverVKN || hareket.receiverVKN === receiverVKN,
            referenceNo: (hareket) => !referenceNumber || hareket.referenceNo === referenceNumber
        };

        const filteredTransactions = transactions.filter(transaction =>
            Object.values(filters).every(filterFn => filterFn(transaction))
        );

        setFilteredTransactions(filteredTransactions);
        setCurrentPage(1);
    }, [startDate, finishDate, bankName, bankBranch, description, receiptNumber, currencyType, accountType, senderVKN, receiverVKN, referenceNumber, transactions]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIds = displayedTransactions.map((_, index) => (currentPage - 1) * itemsPerPage + index);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    const formatCurrency = (value, decimals = 2) => {
        return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    };

    const onChangeBankName = (selectedOption) => {
        setBankName(selectedOption ? selectedOption.value : '');
    };

    const onChangeBankBranch = (selectedOption) => {
        setBankBranch(selectedOption ? selectedOption.value : '');
    };

    const onChangeAccountType = (selectedOption) => {
        setAccountType(selectedOption ? selectedOption.value : '');
    };

    const onChangeCurrencyType = (selectedOption) => {
        setCurrencyType(selectedOption ? selectedOption.value : '');
    };

    const handleRefresh = () => {
        fetchTransactions();
    };

    const handleExportToExcel = () => {
        let transactionsToExport = displayedTransactions;

        if (selectedItems.length > 0) {
            transactionsToExport = filteredTransactions.filter((_, index) =>
                selectedItems.includes(index)
            );
        } else {
            transactionsToExport = filteredTransactions;
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(transactionsToExport.map(transaction => ({
            "İşlem Tarihi": formatDateString(transaction.date),
            "Banka": transaction.bankName || 'Belirtilmemiş',
            "Banka Hesabı": transaction.branchName || 'Belirtilmemiş',
            "Açıklama": transaction.explanation || 'Belirtilmemiş',
            "Tutar": `${formatCurrency(transaction.amount)} ${transaction.currencyType}`,
            "Bakiye": `${formatCurrency(transaction.balance)} ${transaction.currencyType}`,
            "Para Birimi": transaction.currencyType || 'Belirtilmemiş',
            "Hesap Türü": transaction.bankAccountType || 'Belirtilmemiş',
            "Borç mu?": transaction.isDebit !== undefined ? (transaction.isDebit ? "Evet" : "Hayır") : 'Belirtilmemiş',
            "Gönderen VKN": transaction.senderVKN || 'Belirtilmemiş',
            "Gönderen IBAN": transaction.senderIBAN || 'Belirtilmemiş',
            "Gönderen Hesap Numarası": transaction.senderAccountNo || 'Belirtilmemiş',
            "Alıcı VKN": transaction.receiverVKN || 'Belirtilmemiş',
            "Alıcı IBAN": transaction.receiverIBAN || 'Belirtilmemiş',
            "Alıcı Hesap Numarası": transaction.receiverAccountNo || 'Belirtilmemiş',
            "İşlem Türü": transaction.transactionType || 'Belirtilmemiş',
            "Makbuz Numarası": transaction.receiptNumber || 'Belirtilmemiş',
            "Referans Numarası": transaction.referenceNo || 'Belirtilmemiş'
        })));

        XLSX.utils.book_append_sheet(workbook, worksheet, "Hesap Hareketleri");
        XLSX.writeFile(workbook, "Hesap Hareketleri - Excel Raporu.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Hesap Hareketleri")} pageTitle={t("Hesap İşlemleri")} pageLink="online-banka" />
                    <Col xs={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 25, marginRight: 25 }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="date" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_Tarih")}
                                                    </Label>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                type="date"
                                                                className="form-control"
                                                                id="startDate"
                                                                value={startDate}
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                type="date"
                                                                className="form-control"
                                                                id="finishDate"
                                                                value={finishDate || new Date().toISOString().split('T')[0]}
                                                                onChange={(e) => setFinishDate(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="bankName" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_Banka")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketleri_BankaSeciniz")}
                                                        value={bankNameSelectOptions.find(option => option.value === bankName)}
                                                        isClearable
                                                        onChange={onChangeBankName}
                                                        options={bankNameSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="bankBranch" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_BankaSubesi")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketleri_BankaSubesiSecin")}
                                                        value={bankBranchSelectOptions.find(option => option.value === bankBranch)}
                                                        isClearable
                                                        onChange={onChangeBankBranch}
                                                        options={bankBranchSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="description" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_Aciklama")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="description"
                                                        placeholder={t("HesapHareketleri_Aciklama")}
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="receiptNumber" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_MakbuzNumarasi")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="receiptNumber"
                                                        placeholder={t("HesapHareketleri_MakbuzNumarasi")}
                                                        value={receiptNumber}
                                                        onChange={(e) => setReceiptNumber(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="currencyType" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_ParaBirimi")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketleri_ParaBirimiSecin")}
                                                        value={currencyTypeSelectOptions.find(option => option.value === currencyType)}
                                                        isClearable
                                                        onChange={onChangeCurrencyType}
                                                        options={currencyTypeSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="accountType" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_HesapTuru")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketleri_HesapTuruSecin")}
                                                        value={accountTypeSelectOptions.find(option => option.value === accountType)}
                                                        isClearable
                                                        onChange={onChangeAccountType}
                                                        options={accountTypeSelectOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="senderVKN" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_GonderenVkn")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="senderVKN"
                                                        placeholder={t("HesapHareketleri_GonderenVkn")}
                                                        value={senderVKN}
                                                        onChange={(e) => setSenderVKN(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="receiverVKN" className="form-label text-capitalize">
                                                        {t("HesapHareketleri_AliciVkn")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="receiverVKN"
                                                        placeholder={t("HesapHareketleri_AliciVkn")}
                                                        value={receiverVKN}
                                                        onChange={(e) => setReceiverVKN(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="referenceNumber" className="form-label text-capitalize">
                                                    {t("HesapHareketleri_ReferansNumarasi")}
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control text-capitalize"
                                                        id="referenceNumber"
                                                        placeholder={t("HesapHareketleri_ReferansNumarasi")}
                                                        value={referenceNumber}
                                                        onChange={(e) => setReferenceNumber(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            {/* <div className="text-start">
                                                <button type="submit" className="btn btn-primary text-capitalize" onClick={() => ListTransactions()}>
                                                    Listele
                                                </button>
                                            </div> */}
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <Input
                                                    type="number"
                                                    placeholder={t("HesapHareketleri_SayfaBasinaKayitSayisi")}
                                                    className="form-control"
                                                    style={{ width: '200px' }}
                                                    value={itemsPerPage}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value.replace(/^0+/, '');
                                                        if (newValue === '') {
                                                            setItemsPerPage('');
                                                        } else {
                                                            const parsedValue = parseInt(newValue, 10);
                                                            if (!isNaN(parsedValue) && parsedValue > 0) {
                                                                setItemsPerPage(parsedValue);
                                                                setCurrentPage(1);
                                                            } else {
                                                                setItemsPerPage(1);
                                                                setCurrentPage(1);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <Button color="success" onClick={handleExportToExcel} className="me-2">
                                                {t("Bakiyeler_ExcelEAktar")}
                                                </Button>
                                                <Button color="primary" onClick={handleRefresh}>
                                                    {t("Bakiyeler_Yenile")}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="checkAll" value="option" onChange={handleSelectAll} checked={selectedItems.length === displayedTransactions.length} />
                                                            </div>
                                                        </th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_IslemTarihi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_Banka")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_BankaSubesi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_Aciklama")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_Tutar")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_Bakiye")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_ParaBirimi")}</th>
                                                        <th className='text-capitalize'>{t("HesapHareketleri_HesapTuru")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_BorcMu")}</th>
                                                        {/* Borç'mu? */}
                                                        <th className="text-capitalize">{t("HesapHareketleri_GonderenVkn")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_GonderenIban")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_GonderenHesapNumarasi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_AliciVkn")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_AliciIban")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_AliciHesapNumarasi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_IslemTuru")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_MakbuzNumarasi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketleri_ReferansNumarasi")}</th>
                                                    </tr>
                                                </thead>
                                                {displayedTransactions.length >= 1 && (
                                                    <tbody>
                                                        {displayedTransactions.map((transaction, index) => {
                                                            const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                                            const isChecked = selectedItems.includes(globalIndex);

                                                            return (
                                                                <tr key={index} className={isChecked ? "table-active" : ""}>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <Input className="form-check-input" type="checkbox" name="chk_child" value="option1" checked={isChecked} onChange={() => handleSelectItem(globalIndex)} />
                                                                        </div>
                                                                    </td>
                                                                    <td>{formatDateString(transaction.date)}</td>
                                                                    <td>{transaction.bankName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.branchName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.explanation || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td style={{ color: transaction.amount < 0 ? 'red' : (transaction.amount > 0 ? 'green' : 'inherit') }}>
                                                                        {transaction.amount > 0 ? '+' : ''}{formatCurrency(transaction.amount)} {transaction.currencyType}
                                                                    </td>
                                                                    <td>{formatCurrency(transaction.balance)} {transaction.currencyType}</td>
                                                                    <td>{transaction.currencyType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.bankAccountType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>
                                                                        <div className={`status-badge bg-gradient ${transaction.isDebit === true ? "bg-success" : "bg-danger"}`}>
                                                                            {transaction.isDebit ? "Evet" : "Hayır"}
                                                                        </div>
                                                                    </td>
                                                                    <td>{transaction.senderVKN || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.senderIBAN || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.senderAccountNo || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.receiverVKN || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.receiverIBAN || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.receiverAccountNo || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.transactionType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.receiptNumber || t("Bakiyeler_Belirtilmemis")}</td>
                                                                    <td>{transaction.referenceNo || t("Bakiyeler_Belirtilmemis")}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                )}
                                            </Table>

                                            {displayedTransactions.length <= 0 && (
                                                <Row>
                                                    <div style={{ width: "100%", height: 45 }} className="d-flex justify-content-center align-items-center border-2 border-bottom">
                                                        {t("HesapHareketleri_Nodata")}
                                                    </div>
                                                </Row>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-between mt-3">
                                            <div className="text-muted">
                                                {t("SelectCompanyPopup_Records")} {((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, transactions.length)} {t("SelectCompanyPopup_Of")} ({transactions.length})
                                            </div>
                                            <div>
                                                <ul className="pagination mb-0">
                                                    <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                        <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage - 1)}>{t("Bakiyeler_Onceki")}</Link>
                                                    </li>
                                                    {totalPages > 0 && Array.from({ length: totalPages }, (_, pageIndex) => (
                                                        <li className={`page-item ${currentPage === pageIndex + 1 && 'active'}`} key={pageIndex}>
                                                            <Link className="page-link" to="#" onClick={() => handleChangePage(pageIndex + 1)}>{pageIndex + 1}</Link>
                                                        </li>
                                                    ))}
                                                    <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                                                        <Link className="page-link text-capitalize" to="#" onClick={() => handleChangePage(currentPage + 1)}>{t("Bakiyeler_Sonraki")}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HesapHareketleri;