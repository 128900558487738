import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from "react-i18next";

const EmailVerifyFailed = () => {
    const { t } = useTranslation();
    document.title = "E-posta Doğrulanamadı | Tanasoft";

    return (
        <React.Fragment>
            <div className="auth-page-content d-flex justify-content-center align-items-center min-vh-100">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">
                                <CardBody className="p-4 text-center">
                                    <div className="avatar-lg mx-auto mt-2 d-flex justify-content-center align-items-center">
                                        <div className="avatar-title bg-light text-danger display-3 rounded-circle d-flex justify-content-center align-items-center">
                                            <i className="ri-close-circle-fill"></i>
                                        </div>
                                    </div>
                                    <div className="mt-4 pt-2">
                                        <h4>E-Posta Doğrulanamadı</h4>
                                        <p className="text-muted mx-4">E-posta adresin doğrulanamadı. Lütfen daha sonra tekrar deneyin.</p>
                                        <div className="mt-4">
                                            <Link to="/login" className="btn btn-danger w-100">Giriş ekranına geri dön</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmailVerifyFailed;