import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import GunSonuBakiyeChart from "./Charts/GunSonuBakiyeChart";
import TahsilatRaporuChart from "./Charts/TahsilatRaporuChart";
import OnlineBakiyeChart from "./Charts/OnlineBakiyeChart";
import IslemTipineGöreChart from './Charts/IslemTipineGoreChart';
import { isSessionExpired } from '../../helpers/api_helper';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

const DashboardScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    document.title = "Ana Sayfa | Tanasoft";

    useEffect(() => {
        setTimeout(() => {
            if (isSessionExpired()) {
                localStorage.removeItem("userData");
                localStorage.removeItem("companyData");
                navigate('/login');
            }
        }, 1000);
    }, [navigate]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="h-100">
                                <Col xs={12}>
                                    <OnlineBakiyeChart></OnlineBakiyeChart>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Card style={{ height: 630 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={12}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_IslemTipineGoreHareketler_Gelen")}
                                                        </h6>
                                                    </Col>

                                                </CardHeader>
                                                <CardBody>
                                                    <div style={{ height: '500px', width: '100%' }}>
                                                        <IslemTipineGöreChart />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12} lg={6}>
                                            <Card style={{ height: 630 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={7}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_GunSonuBakiye")}
                                                        </h6>
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <GunSonuBakiyeChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} >
                                            <Card style={{ height: 670 }}>
                                                <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                    <Col xs={6}>
                                                        <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                            {t("Dashboard_TahsilatRaporu")}
                                                        </h6>
                                                    </Col>
                                                </CardHeader>
                                                <div className="card-body">
                                                    <TahsilatRaporuChart dataColors='["--vz-success"]' />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </React.Fragment>
    );
};

export default DashboardScreen;