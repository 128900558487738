import React, { useState, useEffect, useRef } from 'react';
import { getBalanceWithBanks } from "../../../slices/thunks";
import { useDispatch } from 'react-redux';
import { Col, Card, CardBody, CardTitle, CardText, Container, CardHeader, Row, Button, Input } from 'reactstrap';
import { useTranslation } from "react-i18next";
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import * as XLSX from 'xlsx';

const BankaBakiyeler = () => {
    const cookieData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [currencyType, setCurrencyType] = useState('');
    const [bankName, setBankName] = useState('');
    const pageRef = useRef(null);
    document.title = t("BankaBakiyeler") + " | Tanasoft";

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const responseData = await dispatch(getBalanceWithBanks(cookieData.companyID));
                setData(responseData[0].balances);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchBankData();
    }, [dispatch]);

    if (!data) {
        return <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>;
    }

    const uniqueCurrencies = [...new Set(data.map(item => item.currencyType).filter(currency => currency))];
    const uniqueBankNames = [...new Set(data.map(item => item.banks.map(bank => bank.bankName)).flat().filter(bank => bank))];
    const groupedByBankName = data.flatMap(item => item.banks.map(bank => ({ ...bank, currencyType: item.currencyType }))).reduce((acc, bank) => {
        acc[bank.bankName] = acc[bank.bankName] || [];
        acc[bank.bankName].push(bank);
        return acc;
    }, {});

    const renderCard = (bank, currencyType, bankCount) => {
        const { bankLogoPath, bankName, balance, blokedBalance, bankId } = bank;
        const formattedBalance = balance ? balance.toLocaleString('tr-TR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : t("Bakiyeler_ZeroData");
        const formattedBlockedBalance = blokedBalance ? blokedBalance.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : t("Bakiyeler_ZeroData");

        return (
            <Col xs={12} md={6} lg={4} className="mb-4" key={`${bankId}-${currencyType}`}>
                <Card className="shadow-sm">
                    <CardHeader className="text-center" style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CardTitle className="mb-0">
                            <img src={bankLogoPath} alt={`${bankName} logo`} style={{ maxWidth: '100px', height: 'auto' }} />
                        </CardTitle>
                    </CardHeader>
                    <CardBody className="text-center">
                        <CardText>
                            <strong>{t("Bakiyeler_Bakiye")}: </strong> {formattedBalance} {currencyType === "TANIMSIZ" ? t("Dashboard_OnlineBakiyeChart_Undefined") : currencyType}
                        </CardText>
                        <CardText>
                            <strong>{t("Bakiyeler_BlokeBakiye")}: </strong> {formattedBlockedBalance} {currencyType === "TANIMSIZ" ? t("Dashboard_OnlineBakiyeChart_Undefined") : currencyType}
                        </CardText>
                        <CardText>
                            <strong>{t("Bakiyeler_HesapSayisi")}: </strong> {bankCount || t("Bakiyeler_ZeroData")}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        );
    };

    const exportToExcel = () => {
        const filteredData = data
            .flatMap(item =>
                item.banks
                    .filter(bank => (!bankName || bank.bankName === bankName))
                    .map(bank => ({
                        [t("Bakiyeler_Banka")]: bank.bankName,
                        [t("Bakiyeler_ParaBirimi")]: item.currencyType,
                        [t("Bakiyeler_Bakiye")]: bank.balance,
                        [t("Bakiyeler_BlokeBakiye")]: bank.blokedBalance,
                        [t("Bakiyeler_HesapSayisi")]: bank.accountCount
                    }))
            )
            .filter(item => !currencyType || item[t("Bakiyeler_ParaBirimi")] === currencyType)
            .filter(item => item[t("Bakiyeler_Banka")])
            .sort((a, b) => a[t("Bakiyeler_Banka")].localeCompare(b[t("Bakiyeler_Banka")]));

        if (filteredData.length === 0) {
            alert(t("Bakiyeler_NoDataNotify"));
            return;
        }

        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, t("BankaBakiyeler"));
        XLSX.writeFile(workbook, t("BankaBakiyeler") + ".xlsx");
    };

    return (
        <div className="page-content" ref={pageRef}>
            <Container fluid>
                <BreadCrumb title={t("BankaBakiyeler")} pageTitle={t("Bakiyeler_Bakiye")} pageLink="bankabakiyeler" />
                <Row className="mt-4">
                    <Col xs={12} md={4} lg={3} className="mb-3" style={{ position: 'sticky', left: '0', top: '0', zIndex: 10, textAlign: 'left' }}>
                        <label htmlFor="currencySelect" style={{ marginRight: '10px' }}>{t("Bakiyeler_ParaBirimi")}:</label>
                        <Input
                            type='select'
                            id="currencySelect"
                            value={currencyType}
                            onChange={(e) => setCurrencyType(e.target.value)}
                        >
                            <option value="">{t("Bakiyeler_TumKurlar")}</option>
                            {uniqueCurrencies.map((currency) => (
                                <option key={currency} value={currency}>
                                    {currency === "TANIMSIZ" ? t("Dashboard_OnlineBakiyeChart_Undefined") : currency}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col xs={12} md={4} lg={3} className="mb-3" style={{ position: 'sticky', right: '0', top: '0', zIndex: 10, textAlign: 'left' }}>
                        <label htmlFor="bankNameSelect" style={{ marginRight: '10px' }}>{t("Bakiyeler_BankaSecin")}:</label>
                        <Input
                            type='select'
                            id="bankNameSelect"
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                        >
                            <option value="">{t("Bakiyeler_TumKurlar")}</option>
                            {uniqueBankNames.map((bankName) => (
                                <option key={bankName} value={bankName}>
                                    {bankName}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={{ size: 3, offset: 3 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', justifyContent: 'flex-end' }}>
                        <Button color="primary" onClick={exportToExcel} style={{ marginLeft: '10px' }}>{t("Bakiyeler_ExcelEAktar")}</Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    {Object.keys(groupedByBankName)
                        .filter(name => !bankName || name === bankName)
                        .flatMap(name => groupedByBankName[name]
                            .filter(item => (!currencyType || item.currencyType === currencyType))
                            .map((bank, index) => renderCard(bank, bank.currencyType, bank.accountCount, `${bank.bankId}-${index}`))
                        )
                    }
                </Row>
            </Container>
        </div>
    );
};

export default BankaBakiyeler;