import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../screens/Auth/Login";
import Register from "../screens/Auth/Register";
import Logout from "../screens/Auth/Logout";
import DashboardScreen from "../screens/Dashboard/DashboardScreen";
import Profile from "../screens/Profile/Profile";
import HesapHareketleri from "../screens/OnlineBanka/HesapIslemleri/HesapHareketleri";
import Bakiyeler from "../screens/OnlineBanka/Bakiye/Bakiyeler";
import HesapHareketRaporu from "../screens/OnlineBanka/Raporlar/HesapHareketRaporu";
import OnlineBanka from "../screens/OnlineBanka/OnlineBanka";
import Users from "../screens/Admin/Users";
import EditFirm from "../screens/Admin/EditFirm";
import InviteUser from "../screens/Admin/InviteUser";
import EmailAuthSuccess from "../screens/Email/AuthSuccess";
import EmailAuthFailed from "../screens/Email/AuthFailed";
import EmailVerifySuccess from "../screens/Email/VerifySuccess";
import EmailVerifyFailed from "../screens/Email/VerifyFailed";
import BankaBakiyeler from "../screens/OnlineBanka/Bakiye/BankaBakiyeler";

const authProtectedRoutes = [
    { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
    { path: "*", component: <Navigate to="/dashboard" /> },
    { path: "/index", component: <DashboardScreen /> },
    { path: "/dashboard", component: <DashboardScreen /> },
    { path: "/profile", component: <Profile /> },

    // Online Banka
    { path: "/online-banka", component: <OnlineBanka /> },
    { path: "/hesap-hareketleri", component: <HesapHareketleri /> },
    { path: "/bakiyeler", component: <Bakiyeler /> },
    { path: "/banka-bakiyeler", component: <BankaBakiyeler /> },
    { path: "/hesap-hareket-raporu", component: <HesapHareketRaporu /> },

    // Admin
    { path: "/users", component: <Users /> },
    { path: "/invite-user", component: <InviteUser /> },
    { path: "/edit-firm-details", component: <EditFirm /> },

    // Email
    { path: "/email-auth-enabled", component: <EmailAuthSuccess /> },
    { path: "/email-auth-failed", component: <EmailAuthFailed /> },
];

const publicRoutes = [
    { path: "/logout", component: <Logout /> },
    { path: "/login", component: <Login /> },
    { path: "/register-by-email", component: <Register />},
    { path: "/email-verified", component: <EmailVerifySuccess /> },
    { path: "/email-verify-failed", component: <EmailVerifyFailed /> },
];

export { authProtectedRoutes, publicRoutes };