import React, { useEffect, useState, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import axios from 'axios';
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import * as url from '../../../helpers/url_helper';
import { Col, FormGroup, Button, Row, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import * as XLSX from 'xlsx';
import { useTranslation } from "react-i18next";

const GunSonuBakiyeChart = ({ dataColors }) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState({ xAxisData: [], seriesData: [] });
    const [currencyType, setCurrencyType] = useState('TRY');
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hasData, setHasData] = useState(true);
    const chartRef = useRef(null);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");

                const todayDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                };

                const response = await axios.post(url.GET_BALANCE_BY_CURRENCY, {
                    "firmBankAccounts": [],
                    "firms": [companyData.companyID],
                    "startDate": todayDate(new Date(new Date().setDate(new Date().getDate() - 7))),
                    "endDate": todayDate(new Date()),
                    "currencyType": ""
                });

                let responseData = response.data;

                responseData = responseData.map(item => ({
                    ...item,
                    currencyType: item.currencyType === 'TL' ? 'TRY' : item.currencyType
                }));

                const uniqueCurrencies = [...new Set(responseData.map(item => item.currencyType))];
                setCurrencyOptions(uniqueCurrencies);

                const last7Days = [...new Set(responseData.flatMap(item =>
                    item.periodBalances.map(balance => balance.date)))];

                const formattedDates = last7Days.map(date => {
                    const d = new Date(date);
                    return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
                });

                const filteredBalances = responseData.flatMap(item =>
                    item.periodBalances.filter(balance =>
                        item.currencyType === currencyType
                    ).map(balance => ({
                        date: balance.date,
                        balance: balance.balance
                    }))
                );

                const xAxisData = formattedDates;
                const seriesData = last7Days.map(date => {
                    const foundBalance = filteredBalances.find(balance => balance.date === date);
                    return foundBalance ? foundBalance.balance : 0;
                });

                setChartData({
                    xAxisData,
                    seriesData
                });

                setHasData(seriesData.some(value => value !== 0));

            } catch (error) {
                console.error('API çağrısı sırasında bir hata oluştu:', error.response ? error.response.data : error.message);
                setHasData(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currencyType, i18n.language]);

    const chartAreaColors = getChartColorsArray(dataColors);
    const option = {
        grid: {
            left: '3%',
            right: '0%',
            bottom: '3%',
            top: '4%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                let result = `<b>${params[0].name}</b><br/>`;
                params.forEach(item => {
                    let formattedValue = new Intl.NumberFormat(i18n.language === 'tr' ? 'tr-TR' : 'en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(item.value);
                    result += `${item.seriesName}: ${formattedValue}<br/>`;
                });
                return result;
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: '#ccc',
            borderWidth: 1,
            textStyle: {
                color: '#333'
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: chartData.xAxisData,
            axisLine: {
                lineStyle: {
                    color: '#acb5bd'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#acb5bd'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        series: [{
            name: t('Dashboard_GunSonuBakiye_LastBalance'),
            type: 'line',
            smooth: true,
            data: chartData.seriesData,
            areaStyle: {
                color: 'rgba(140, 85, 149, 0.5)'
            },
            lineStyle: {
                color: 'rgba(140, 85, 149, 1)'
            },
            itemStyle: {
                color: '#ffffff',
                borderColor: 'rgba(140, 85, 149, 1)',
                borderWidth: 2
            },
            symbol: 'circle',
            symbolSize: 8,
        }],
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: chartAreaColors[0],
        backgroundColor: chartAreaColors[1],
    };

    const downloadChart = () => {
        const chart = chartRef.current.getEchartsInstance();
        if (chart) {
            const url = chart.getDataURL({ type: 'png', pixelRatio: 2, backgroundColor: '#fff' });
            const link = document.createElement('a');
            link.href = url;
            link.download = 'chart.png';
            link.click();
        }
    };

    const exportToExcel = () => {
        if (chartData) {
            const ws = XLSX.utils.json_to_sheet(
                chartData.xAxisData.map((label, index) => ({
                    [t('Dashboard_GunSonuBakiye_Date')]: label,
                    [t('Dashboard_GunSonuBakiye_Balance')]: chartData.seriesData[index]
                })),
                {
                    cellStyles: true
                }
            );

            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let col = 1; col <= 1; col++) {
                for (let row = range.s.r + 1; row <= range.e.r; row++) {
                    const cellAddress = { c: col, r: row };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    if (!ws[cellRef]) ws[cellRef] = {};
                    ws[cellRef].z = currencyType === 'USD' ? '$#,##0.00' :
                        currencyType === 'EUR' ? '€#,##0.00' :
                            '₺#,##0.00';
                }
            }

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, t('Dashboard_GunSonuBakiye_ExcelFileName') + ".xlsx");
        }
    };

    const dropdownToggle = () => setDropdownOpen(prevState => !prevState);

    const handleDropdownAction = (action) => {
        if (action === 'download') {
            downloadChart();
        } else if (action === 'excel') {
            exportToExcel();
        }
    };

    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner color="primary" />
                </div>
            ) : (
                <React.Fragment>
                    <Row className="mb-3">
                        {hasData && (
                            <>
                                <Col xs={3}>
                                    <Input
                                        type="select"
                                        value={currencyType}
                                        onChange={(e) => setCurrencyType(e.target.value)}
                                    >
                                        {currencyOptions.map(currency => (
                                            <option key={currency} value={currency}>{currency}</option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col xs={9} style={{ textAlign: 'right' }}>
                                    <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
                                        <DropdownToggle caret color="primary">
                                            {t("Dashboard_GunSonuBakiye_Export")}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => handleDropdownAction('download')}>
                                                {t("Dashboard_GunSonuBakiye_Download")}
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleDropdownAction('excel')}>
                                                {t("Dashboard_GunSonuBakiye_Excel")}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </>
                        )}
                    </Row>
                    {hasData ? (
                        <ReactEcharts
                            ref={chartRef}
                            option={option}
                            style={{ height: '400px', width: '100%' }}
                        />
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                            <h5>{t("Dashboard_NoData")}</h5>
                        </div>
                    )}
                </React.Fragment>
            )}
        </>
    );
};

export default GunSonuBakiyeChart;