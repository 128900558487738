import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Spinner, Alert } from 'reactstrap';
import { getUserDataFromAuth, changeUserInfo, enable2FA, enable2FAConfirm, disable2FA, enableEmailAuth, disableEmailAuth, getFirmsByUserId } from "../../slices/thunks";
import { Link, useNavigate } from 'react-router-dom';
import { sendNotify, useHandleError } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import FeatherIcon from 'feather-icons-react';
import Enable2FAPopup from "../../Components/Common/Enable2FAPopup";

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleError = useHandleError();
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [authType, setAuthType] = useState("");
    const [userRole, setUserRole] = useState("");
    const [companies, setCompanies] = useState([]);
    const [openEnable2FAPopup, set2FAPopup] = useState(false);
    const [enable2FAResponse, setEnable2FAResponse] = useState(null);
    document.title = t('Profile_Title') + " | Tanasoft";

    useEffect(() => {
        dispatch(getUserDataFromAuth()).then(response => {
            const userId = response["id"];
            setFirstName(response["firstName"] || t("Unknown"));
            setLastName(response["lastName"] || t("Unknown"));
            setEmail(response["email"] || t("Unknown"));
            setAuthType(response["authenticatorType"] || "None");

            if (response.roles && response.roles.length > 0) {
                const userRole = response.roles[0].roleName;
                setUserRole(userRole);
            }

            dispatch(getFirmsByUserId(userId)).then(response => {
                const companyData = response.map(company => ({
                    firmId: company["firmId"],
                    firmName: company["firmName"],
                    vknNumber: company["vknNumber"]
                }));

                setCompanies(companyData);
            }).catch(err => {
                handleError(err);
            });
        }).catch(err => {
            handleError(err);
        });
    }, []);

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleProfileFormSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const newPassword = data.get('newPasswordInput');
        const confirmPassword = data.get('confirmPasswordInput');

        if (newPassword !== confirmPassword) {
            sendNotify(t("Profile_PasswordsDontMatch"), 'danger');
            return;
        }

        const formDataObject = {
            firstname: data.get('firstnameInput'),
            lastname: data.get('lastnameInput'),
            email: data.get('emailInput'),
            password: data.get('passwordInput'),
            newPassword: data.get('newPasswordInput'),
            confirmPassword: data.get('confirmPasswordInput')
        };

        setLoading(true);
        dispatch(changeUserInfo(formDataObject)).then(() => {
            setLoading(false);
            navigate('/login');
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleEnable2FA = () => {
        setLoading(true);
        dispatch(enable2FA()).then((response) => {
            setLoading(false);
            setEnable2FAResponse(response);
            set2FAPopup(true);
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleDisable2FA = () => {
        setLoading(true);
        dispatch(disable2FA()).then(() => {
            setLoading(false);
            sendNotify(t('Popup_2FADisabled'), 'success');
            setAuthType("None");
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleEnable2FAConfirm = (code) => {
        if (code) {
            setLoading(true);
            dispatch(enable2FAConfirm(code)).then(() => {
                setLoading(false);
                sendNotify(t("Popup_2FAEnabled"), 'success');
                set2FAPopup(false);
                setAuthType("Otp");
            }).catch((err) => {
                setLoading(false);
                handleError(err);
            });
        } else {
            sendNotify(t("Popup_AuthCodeCannotBeEmpty"), 'danger');
        }
    };

    const handleEnableEmailAuth = () => {
        setEmailLoading(true);
        dispatch(enableEmailAuth()).then(() => {
            setEmailLoading(false);
            sendNotify(t('Popup_EmailAuthRequestSend'), 'success');
            setAuthType("Email");
        }).catch((err) => {
            setEmailLoading(false);
            handleError(err);
        });
    };

    const handleDisableEmailAuth = () => {
        setEmailLoading(true);
        dispatch(disableEmailAuth()).then(() => {
            setEmailLoading(false);
            sendNotify(t('Popup_EmailAuthDisabled'), 'success');
            setAuthType("None");
        }).catch((err) => {
            setEmailLoading(false);
            handleError(err);
        });
    };

    return (
        <React.Fragment>
            <div className="page-content pt-4 mb-4 mb-lg-3 pb-lg-4">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img"></div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${FirstName}${LastName}`}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile" />
                                        </div>
                                        <h5 className="fs-16 mb-1">{FirstName} {LastName}</h5>
                                        <p className="text-muted mb-0">{userRole}</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">{t('Profile_Companies')}</h5>
                                        </div>
                                    </div>
                                    {companies.map((company) => (
                                        <div key={company.firmId} className="mb-3 d-flex">
                                            <Input type="text" className="form-control" value={company.firmName} disabled />
                                        </div>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                {t('Profile_UserInformation')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                {t('Profile_2FATab')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Form onSubmit={handleProfileFormSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">{t('Profile_Firstname')}</Label>
                                                            <Input type="text" className="form-control" name='firstnameInput' id="firstnameInput" placeholder={t("Profile_FirstnamePlaceholder")} defaultValue={FirstName} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Profile_Lastname')}</Label>
                                                            <Input type="text" className="form-control" name='lastnameInput' id="lastnameInput" placeholder={t("Profile_LastnamePlaceholder")} defaultValue={LastName} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{t('Profile_Email')}</Label>
                                                            <Input type="email" className="form-control" name='emailInput' id="emailInput" placeholder={t("Profile_EmailPlaceholder")} defaultValue={Email} disabled />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="passwordInput" className="form-label">{t('Profile_Password')}</Label>
                                                            <Input type="password" className="form-control" name='passwordInput' id="passwordInput" placeholder={t("Profile_PasswordPlaceholder")} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="newPasswordInput" className="form-label">{t('Profile_NewPassword')}</Label>
                                                            <Input type="password" className="form-control" name="newPasswordInput" id="newPasswordInput" placeholder={t('Profile_NewPasswordPlaceholder')} />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="confirmPasswordInput" className="form-label">{t('Profile_ConfirmPassword')}</Label>
                                                            <Input type="password" className="form-control" name='confirmPasswordInput' id="confirmPasswordInput" placeholder={t('Profile_ConfirmPasswordPlaceholder')} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <Button type="submit" color="primary" disabled={loading}>
                                                                {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                                {t('Profile_UpdateButton')}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                <div className="flex-grow-1">
                                                    <h6 className="fs-14 mb-1">{t("Profile_2FA")}</h6>
                                                    <p className="text-muted">{t('Profile_Enable2FADesc')}</p>
                                                </div>
                                                <div className="flex-shrink-0 ms-sm-3">
                                                    {authType === "Otp" ? (
                                                        <Button type="button" color="danger" className="btn btn-danger" onClick={handleDisable2FA} disabled={loading}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_Disable2FABtn")}
                                                        </Button>
                                                    ) : (
                                                        <Button type="button" color="primary" onClick={handleEnable2FA} disabled={loading}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_Enable2FABtn")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                <div className="flex-grow-1">
                                                    <h6 className="fs-14 mb-1">{t("Profile_EmailAuth")}</h6>
                                                    <p className="text-muted">{t('Profile_EnableEmailAuthDesc')}</p>
                                                </div>
                                                <div className="flex-shrink-0 ms-sm-3">
                                                    {authType === "Email" ? (
                                                        <Button type="button" color="danger" className="btn btn-danger" onClick={handleDisableEmailAuth} disabled={emailLoading}>
                                                            {emailLoading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_DisableEmailAuth")}
                                                        </Button>
                                                    ) : (
                                                        <Button type="button" color="primary" onClick={handleEnableEmailAuth} disabled={emailLoading}>
                                                            {emailLoading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_EnableEmailAuth")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Enable2FAPopup isOpen={openEnable2FAPopup} toggle={() => set2FAPopup(!openEnable2FAPopup)} onConfirm={handleEnable2FAConfirm} value={enable2FAResponse} loading={loading} />
        </React.Fragment>
    );
};

export default Profile;