import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Col, Row} from 'reactstrap';

const BreadCrumb = ({title, pageTitle, pageLink}) => {
    const history = useNavigate();

    return (
        <React.Fragment>
            <Col xs={12}>
                <Col className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{title}</h4>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><Link to={`/${pageLink}`}>{pageTitle}</Link></li>
                            <li className="breadcrumb-item active">{title}</li>
                        </ol>
                    </div>
                </Col>
            </Col>
        </React.Fragment>
    );
};

export default BreadCrumb;